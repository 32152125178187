.w-md-editor-toolbar li button {
  height: 100%;
}

.w-md-editor-toolbar-divider {
  height: 0;
}

.w-md-editor-bar {
  display: none;
}

.w-md-editor-text {
  font-size: inherit;
}
