*:not(pre) {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fff !important;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.print {
  display: none;
}
.no-print {
  display: block;
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  font-size: 20px;
  opacity: 0.6;
  margin-right: -5px;
  cursor: pointer;
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }

  html,
  body {
    height: 99%;
  }

  nav,
  header,
  footer,
  video,
  audio,
  object,
  embed {
    display: none !important;
  }

  p a {
    word-wrap: break-word;
  }

  .print {
    display: block;
  }
  .no-print {
    display: none;
  }
}
